<template>
	<div class="ct ranking specialty">
		<div class="cstom">
			{{title}} <div class="count">{{list ? list.length : 0}}</div>
			<template v-for="(item, idx) in list">
				 <template v-if="idx >= pos && idx < pos + 3">
					<slot :item="item" :index="idx"></slot>
				 </template>
			</template>
		</div>
		<div class="updown_btn" v-if="list && list.length > 3">
			<img src="/images/up_btn.png" alt="업버튼" @click="pos = Math.max(pos - 1, 0)"/>
			<img src="/images/down_btn.png" alt="다운버튼" @click="pos = Math.min(pos + 1, list.length - 3)"/>
		</div>
	</div>
</template>

<script>
export default {
	props: { title:String, list:Array, },
	data() { 
		return { pos : 0,}
	},
}
</script>