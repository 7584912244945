<template>
	<scroll :tagNm="tagName || 'div'" :winScroll="true" :style="{paddingTop: height + 'px', display:'inline-block'}" @scroll="handleScroll">
		<component ref="fix" :style="style" :is="tagName || 'div'" :class="fixClass">
			<slot></slot>
		</component>
		<slot name="conts"></slot>
		<div style="clear:both"></div>
	</scroll>
</template>

<script>
/**
 * 컨텐츠내에서 스크롤시 Fix 영역을 최상단에 고정.
 * 
 * 사용법)
 *      props   : 
 *					tagName    : 컴포넌트 태그명
 *					fixTagName : Fix영역 태그명
 *					fixClass   : Fix영역 클레스
 * 					inContent  : 컨텐츠 내에서만 고정할지 화면 전체내에서 고정할지 여부 (default: false)
 *      emit	:  
 * 					fix-start : 상단고정 시작 이벤트
 * 					fix-end   : 상단고정 종료 이벤트
 *
 * 예시)
 *      <fix-top :inContent="true" fixClass="top-fix">
 *			<template v-slot>상당영역</template>
 *			<template v-slot:conts>고정되지 않는 영역. inContent가 true 일 경우 스크롤이 conts 영역밖으로 나가면 고정이 풀린다.</template>
 *		</fix-top>	
 * 
 */
import scroll from "@/components/Scroll.vue";
export default {
	name: 'FixTop',
	components: {scroll},
	props: {tagName : String, inContent: Boolean, fixTagName:String, fixClass:{} },
	data(){
		return { bound: {}, height: 0, fixType: false }
	},
	/*mounted(){
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.handleScroll);
	},*/
	methods: {
		handleScroll(){
			if(typeof this.$el.getBoundingClientRect == 'function'){
				this.bound = this.$el.getBoundingClientRect();
				//console.log('inputComp', 'handleScroll', this.bound, this);
				var fixType = false;
				if(this.bound.top < 0){
					this.height = this.$refs.fix.offsetHeight;
					if(this.inContent && this.bound.height + this.bound.top < this.height){
						fixType = 'bottom';
					} else {
						fixType = 'top';
					}
				} else {
					fixType = false;
					this.height = 0;
				}
				
				if(this.fixType != fixType){
					this.fixType = fixType;
					if(fixType == 'top'){
						this.$emit('fix-start', fixType);
					} else {
						this.$emit('fix-end', fixType);
					}
				}
			}
		},
	},
	computed: {
		style(){
			var style = {};
			if(this.fixType){
				if(this.fixType == 'top'){
					style.position = 'fixed';
					style.top = '0px';
					style.left = this.bound.left + 'px';
				} else {
					style.position = 'absolute';
					style.top = (this.bound.height - this.height) + 'px';
				}

				if(this.$el){
					style.zIndex = (this.$el.style.zIndex||0) + 1;
				} else {
					style.zIndex = 1;
				}
			}
			return style;
		},
	}
}
</script>