<template>
	<div>
		<!-- 희망근무조건 -->
		<div class="scroll-box_tit">희망근무조건</div>
		<div class="scroll-box">
			<!-- 1set -->
			<div class="view-list career none">
				<div class="layout">
					<div class="list">
						<div class="item ivl05">사업참여형태</div>
						<div class="item-ct"><SelectComp type="text_comma_split" :value="info.hopeEmplPatCdList" cdId="PRO112"/></div>
					</div>
				</div>
				<div class="layout">
					<div class="list">
						<div class="item ivl06">고용형태</div>
						<div class="item-ct"><SelectComp type="text_comma_split" :value="info.hopeBizPatCdList" cdId="PRO113"/></div>
					</div>
				</div>
				<div class="layout flex">
					<div class="list">
						<div class="item ivl06">추가희망근무조건</div>
						<nbsp tagName="div" class="item-ct" :value="info.hopeWorkCondEtcCont"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: { data: Object,},
	methods: {
	},
	computed: {
		info(){
			if(this.data && Object.keys(this.data).length > 0){
				return this.data;
			} else {
				return {};
			}
		}
	}
}
</script>