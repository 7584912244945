<template>
	<div>
		<!-- 기술등급, ncs 자가평가 -->
		<div class="view-list">
			<div class="ct ranking">
				<div class="cstom">
					기술등급_학력기준
					<div class="eval"><grade-img type="SCHOOL_TECH" :grade="info.resumeSchoolTechGradeCd"/></div>
				</div>
			</div>
			<div class="ct ranking">
				<div class="cstom">
					기술등급_자격기준
					<div class="eval"><grade-img type="LICENSE_TECH" :grade="info.resumeLicenseTechGradeCd"/></div>
				</div>
			</div>
			<div class="ct ncs">
				<TecInfoNCS class="cstom" :value="info" @clickItem="popupNcs($event.detailClassCd)">
					<template v-slot:title>NCS 자가평가(직무 / 등급)</template>
				</TecInfoNCS>
			</div>
		</div>
		<!-- 전문분야, 직무분야, 보유기술, 수행완료프로젝트 -->
		<div class="view-list">
			<button-list title="산 업 분 야" :list="info.expertCdList" v-slot="slot">
				<div class="specialty field" :title="code('PRO105', slot.item.cd.substr(0, 1)) + ' / ' + code('PRO143', slot.item.cd)">
					<p>{{code('PRO105', slot.item.cd.substr(0, 1))}} / {{code('PRO143', slot.item.cd)}}</p>
				</div>
			</button-list>
			<button-list title="직 무 분 야" :list="info.dutyCdList" v-slot="slot" class="duty">
				<div class="specialty field" :title="code('PRO141', slot.item.substr(0, 2)) + ' / ' + code('PRO142', slot.item)">
					<p class="cf01">{{code('PRO141', slot.item.substr(0, 2))}} / {{code('PRO142', slot.item)}}</p>
				</div>
			</button-list>
			<button-list title="보 유 기 술" :list="info.techLevelList" v-slot="slot" class="tech">
				<div class="specialty" :title="slot.item.techNm + ' / ' + code('PRO125', slot.item.techLevelCd)">
					<p class="clf">{{slot.item.techNm}}</p>
					<p class="level">/ {{code('PRO125', slot.item.techLevelCd)}}</p>
				</div>
			</button-list>
			<div class="ct ranking cp">
				<div class="cstom">
					수행완료 프로젝트
					<div class="count">{{info.projectEndCnt}}건</div>
					<div class="cp_prj_btn" @click="popupProject">수행완료 프로젝트 모두보기 &#9654;</div>
				</div>
			</div>
		</div>
		<!-- 하단설명  -->
		<div class="view-list guide">
			<div class="guide">
				※ 평균 평가등급 : 수행사 평가와 하이프로 평가의 합계 평균값 입니다.<br/>
				※ 수행사 평가는 최근 5개 프로젝트 평가의 평균값을 의미하며, 하이프로 평가는 전체 수행기간에 대한 평가를 의미합니다.
			</div>
		</div>
	</div>
</template>

<script>
import gradeImg from "@/components/highpro/GradeImg.vue";
import buttonList from "@/components/highpro/TecProfile/TecProfileButtonList.vue";
import TecInfoNCS from '@/components/highpro/TecInfo/TecInfoNCS.vue';
export default {
	components: {
		gradeImg, buttonList, TecInfoNCS

	},
	props: { data: Object,},
	data() { 
		return { 
			codes: {},
			expertCdPos : 0,
			dutyCdPos : 0,
			techLevelPos : 0,
		}
	},
	mounted() {
		this.$store.dispatch("commonCode").then((res) => {
			this.codes = {};
			for(var code in res){
				this.codes[code] = {};
				for(var i in res[code]){
					this.codes[code][res[code][i].cd] = res[code][i].cdNm
				}
			}
		});
	},
	methods: {
		code(cdId, cd){
			switch(cdId){
				default:
					if(this.codes[cdId]){
						return this.codes[cdId][cd];
					}
			}
			return "";
		},
		popupProject(){
        	//console.log('TecProfileSub01', 'popupProject', this.data);
			this.$emit('popup', 'PROJECT');
			if(this.info.dtlViewYn != 'Y'){
				//유료서비스 알림 팝업
				return this.$.popup('/dco/pmg/prf/PRF301P07');
			} else {
				if(this.info.projectEndCnt == 0) {
					alert("수행 완료된 프로젝트가 없습니다.");
				} else {
					this.$.popup('/dco/pmg/prf/PRF301P06', { tecMberSeq: this.info.tecSeq });
				}
			}
		},
		popupNcs(detailClassCd){
			this.$emit('popup', 'NCS');
			this.$.popup('/dco/pmg/prf/PRF301P05', { indMberSeq: this.info.tecSeq, techGradeVerSeq : this.info.techGradeVerSeq, detailClassCd });
		},
	},
	computed: {
		info(){
			if(this.data && Object.keys(this.data).length > 0){
				return this.data;
			} else {
				return {};
			}
		}
	}
}
</script>