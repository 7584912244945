<template>
	<div>
		<!-- 자기소개 -->
		<div class="view-list about_me">
			<div class="about_me">
				<div class="tit">자기소개</div>
				<nbsp :value="info.selfIntroCont"/>
			</div>
		</div>
		<!-- 최종학력 -->
		<div class="scroll-box certi">
			<div class="view-list career">
				<template v-for="(item, idx) in info.schoolList">
					<div class="final_box" :class="{'ivl08' : idx == 0, 'certi': idx > 0}" :key="'school_label' + idx">학력</div>
					<div class="final_box_ct" :key="'school_info' + idx">
						<div class="final_box">{{item.schoolNm}}<br><SelectComp style="color:#C3E82D;line-height:32px;font-size:15px;" cdId="PRO121" v-model="item.schoolDivCd" type="text"></SelectComp><SelectComp style="color:#C3E82D;float:right;line-height:32px;font-size:15px;" cdId="PRO122" v-model="item.schoolStatusCd" type="text"></SelectComp></div>
						<div class="final_box">{{item.majorNm}}</div>
						<div class="final_box year">{{item.entYyyymm | date('yyyy년 MM월')}} ~ {{item.gradYyyymm | date('yyyy년 MM월')}}</div>
						<div class="final_box certi_mark">
							<div class="kosa" v-if="item.kosaScrapStatusCd == '01'">KOSA 인증</div>
							<!-- <div class="confirm" v-if="item.admCfmDate">확정</div> -->
							<div class="confirm" v-if="item.admCfmStatusCd == '03'">확정</div>
						</div>
					</div>
				</template>
			</div>
			<!-- 자격증 -->
			<div class="view-list career" v-if="info.licenseList && info.licenseList.length > 0">
				<template v-for="(item, idx) in info.licenseList">
					<div class="final_box" :class="{'ivl08' : idx == 0, 'certi': idx > 0}" :key="'school_label' + idx">자 격 증</div>
					<div class="final_box_ct" :key="'school_info' + idx">
						<div class="final_box">{{item.licenseNm}}</div>
						<div class="final_box">{{item.acqYyyymm | date('yyyy년 MM월')}}</div>
						<div class="final_box year">{{item.issOrgNm}}</div>
						<div class="final_box certi_mark">
							<div class="kosa" v-if="item.kosaScrapStatusCd == '01'">KOSA 인증</div>
							<div class="confirm" v-if="item.admCfmDate">확정</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: { data: Object,},
	methods: {
	},
	computed: {
		info(){
			if(this.data && Object.keys(this.data).length > 0){
				return this.data;
			} else {
				return {};
			}
		}
	}
}
</script>