<template>
	<div class="SlideComp">
		<slot name="top" />
		<div class="swiper-container" ref="container">
			<div class="swiper-wrapper" ref="wrapper" :style="{ left : (length < 2 ? '0px' : (left - 100) + '%')}">
				<div class="swiper-slide" v-for="n in (length < 2 ? 1 : 3)" :key="length < 3 && n < length ? n-2: getIndex(n - 2)">
					<slot :item="getItem(n - 2)" :index="getIndex(n - 2)">
						<img v-if="type == 'image'" :src="getItem(n - 2)"/>
						<div v-else-if="type == 'html'" v-html="getItem(n - 2)"></div>
						<div v-else>{{getItem(n - 2)}}</div>
					</slot>
				</div>
			</div>
		</div>
		<div class="dot">
            <ul><li v-for="dot in length" :key="dot" :class="{on : dot - 1 == now}" @click="slide(dot - 1)"></li></ul>
        </div>
		<div class="arrow-left" v-if="arrowLeft !== false && length > 1" ><img :src="arrowLeft  || '/images/arrow-left.png'"  alt="슬라이드 왼쪽 화살표" @click="slidePrev()"/></div>
        <div class="arrow-right"  v-if="arrowLeft !== false && length > 1"><img :src="arrowRight || '/images/arrow-right.png'" alt="슬라이드 오른쪽 화살표" @click="slideNext()"/></div>
	</div>
</template>

<script>
/**
 * 슬라이딩 컴포넌트
 *      -> 이미지 등의 컨텐츠를 좌우 슬라이딩으로 출력.
 * 
 * 사용법)
 *      props   : 
 *                  type : 컨텐츠의 타입
 * 					list : 컨텐츠 목록
 *      emit    :  
 * 					start-slide : 슬라이드 시작 이벤트
 * 					end-slide   : 슬라이드 종료 이벤트
 *
 * 예시)
 *      <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
 * 
 */
export default {
	name: 'SlideComp',
	props: {
		type : String,
		list : Array,
		size : Number,
		arrowLeft : {},
		arrowRight: {},
		disabled: Boolean,
	},
	data(){
		return {
			left : 0,
			now : 0,
			slideStart: 0,
			slideEnd  : 0,
			direction : 0,
			timer : null,
		}
	},
	unmounted() {
		if(this.timer){
			clearInterval(this.timer);
		}
	},
	methods: {
		getIndex(n){
			return (this.now + n + this.length) % this.length;
		},
		getItem(n){
			var index = this.getIndex(n);
			return this.list ? this.list[index] : index;
		},
		slideNext(num){ 
			if(num === 0) return;
			num = (num || 1);
			var move = this.slideEnd + num;
			var slideEnd = ((move % this.length) + this.length) % this.length;
			this.slide(slideEnd, num);
		},
		slidePrev(num){
			if(num === 0) return;
			this.slideNext(-(num || 1));
		},
		slide(slideEnd, direction){
			if(this.disabled) return this.$emit('slide-start', this.now, slideEnd, direction, this.now);
			if(this.slideEnd == slideEnd) return;
			if(this.now == slideEnd) return;
			this.slideEnd  = slideEnd;

			if(!direction){
				this.direction = this.slideEnd - this.now;
			} else if(this.direction == 0 || direction/Math.abs(direction) == this.direction/Math.abs(this.direction)){
				this.direction = direction;
			//} else {
			//	this.direction = direction;
			}
			
			if(!this.timer){
				this.slideStart = this.now;
				//console.log('SlideComp', 'slide-start', this.now, slideEnd);
				this.$emit('slide-start', this.slideStart, this.slideEnd, this.direction, this.now);
				this.timer = setInterval(() => {
					var direction = this.direction / Math.abs(this.direction);
					var speed = this.direction * direction;
					this.left -= direction * Math.max(Math.min(speed, 10), 1) * 5;
					if(Math.abs(this.left) >= 100){
						this.now = (this.now + this.length + direction) % this.length;
						if(this.now == this.slideEnd && speed <= 1){
							clearInterval(this.timer);
							this.timer = null;
							this.left = 0;
							//console.log('SlideComp', 'slide-end', this.now, direction);
							this.$emit('slide-end', this.now, direction);
							this.slideStart = this.now;
							this.direction  = 0;
						} else {
							this.left = (this.left + 100) % 100;
							if(speed > 1){
								this.direction -= direction;
							}
						}
					}
				}, 20);
			}
		},
	},
	computed: {
		length(){
			return this.list ? this.list.length : Math.ceil(this.size || 0);
		},
	}
}
</script>
<style scoped>
.SlideComp {
	
}
.swiper-container {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	max-width: 100%;
	height: 100%;
}
.swiper-wrapper {
	position: relative;
	width: 300%;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform,-webkit-transform;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
.swiper-slide {
	width: 33.3333%;
	height: 100%;
	position: relative;
	flex-shrink: 0;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform,-webkit-transform;
	display:inline-block; /*flex : 내용 중앙정렬*/
	align-items:center; /*위아래 기준 중앙정렬*/
	justify-content:center; /*좌우 기준 중앙정렬*/
}
</style>