<template>
	<div>
		<!-- 고객사 평가 리스트 -->
		<div class="scroll-box cstom">
			<template v-if="info.evalList && info.evalList.length > 0">
				<div v-for="(item, idx) in info.evalList" :key="idx" class="evl-box">
					<div class="scroll-box_tit">{{item.projectNm}}</div>
					<div class="view-list career none eval">
						<!-- 프로젝트 기간 -->
						<div class="layout">
							<div class="list cstom">
								<div class="item cstom">프로젝트 기간</div>
								<div class="item-ct cstom">{{item.joinTerm | number}}일
									<span>/</span>
									{{item.joinStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{item.joinEndYyyymmdd | date('yyyy.MM.dd')}}
								</div>
							</div>
						</div>
						<!-- 고객사 평가 -->
						<div class="layout">
							<div class="photo" v-if="item.evaluatorLoginId">
								<img src="/images/cstom_photo.png" alt="사진"/>
							</div>
							<div class="cstom_name" v-if="item.evaluatorLoginId">수행사
								<span>{{item.evaluatorLoginId}}</span>
							</div>
							<template v-if="item.itemDivGrade">
								<div class="evl">
									<div class="item" v-for="(str, idx) in item.itemDivGrade.split('|')" :key="idx">
										<SelectComp type="text" :value="str.split(':')[0]" cdId="PRO110"/>
										<grade-img type="CLIENT_EVAL" :grade="str.split(':')[1]"/>
									</div>
								</div>
							</template>
						</div>
						<!-- 프로젝트 평가 -->
						<div class="layout" v-if="item.evaluatorLoginId">
							<div class="list cstom eval">
								<p>프로젝트 평가<span class="date">{{ item.evalYyyymmdd | date('yyyy.MM.dd') }}</span></p>
								<nbsp tagName="span" :value="item.overallOpinCont"/>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="scroll-box_tit">고객사 평가가 없습니다.</div>
			</template>
		</div>
	</div>
</template>

<script>
import gradeImg from "@/components/highpro/GradeImg.vue";
export default {
	components: { gradeImg },
	props: { data: Object,},
	methods: {
	},
	computed: {
		info(){
			if(this.data && Object.keys(this.data).length > 0){
				return this.data;
			} else {
				return {};
			}
		}
	}
}
</script>