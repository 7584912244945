<template>
	<div>
		 <!-- 재직경력 -->
		<div class="scroll-box_tit">재직 경력</div>
		<div class="scroll-box">
			<template  v-if="info.careerList && info.careerList.length > 0">
				<div class="view-list career none" v-for="(item, idx) in info.careerList" :key="idx">
					<div class="layout">
						<div class="list">
							<div class="item ivl08">회 사 명</div>
							<div class="item-ct">
								{{item.companyNm}}
								<div class="certi_mark">
									<div class="kosa" v-if="item.kosaScrapStatusCd == '01'">KOSA 인증</div>
									<!-- <div class="confirm" v-if="item.admCfmDate">확정</div> -->
									<div class="confirm" v-if="item.admCfmStatusCd == '03'">확정</div>
								</div>
							</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">근무형태</div>
							<SelectComp type="text" tagName="div" class="item-ct" :value="item.workPatCd" cdId="PRO123"/>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">담당업무</div>
							<div class="item-ct">{{item.assigWorkNm}}</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">근무기간</div>
							<div class="item-ct">
								{{item.workStartYyyymm | date('yyyy년 MM월')}} ~ {{item.workEndYyyymm | date('yyyy년 MM월')}}
								<P class="term">[{{item.workMonthCnt | months}}]</P>
							</div>
						</div>
					</div>
				</div>
			</template>
			<div class="view-list career none" v-else>등록 된 재직경력이 없습니다.</div>
		</div>
	</div>
</template>

<script>
export default {
	props: { data: Object,},
	methods: {
	},
	computed: {
		info(){
			if(this.data && Object.keys(this.data).length > 0){
				return this.data;
			} else {
				return {};
			}
		}
	}
}
</script>