<template>
	<div>
		<!-- 프로젝트 경력 -->
		<div class="scroll-box_tit">프로젝트 경력</div>
		<div class="scroll-box">
			<template v-if="info.projectList && info.projectList.length > 0">
				<div class="view-list career none" v-for="(item, idx) in info.projectList" :key="idx">
					<div class="layout">
						<div class="list">
							<div class="item ivl09">프로젝트명</div>
							<div class="item-ct prj">{{item.projectNm}}</div>
							<div class="certi_mark prj">
								<div class="kosa" v-if="item.kosaScrapStatusCd == '01'">KOSA 인증</div>
								<!-- <div class="confirm" v-if="item.admCfmDate">확정</div> -->
								<div class="confirm" v-if="item.admCfmStatusCd == '03'">확정</div>
							</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item ivl08">고객사명</div>
							<div class="item-ct">{{item.clientNm}}</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item ivl08">산업구분</div>
							<SelectComp type="text" tagName="div" class="item-ct" :value="item.clientIndtypeClassCd" cdId="PRO105"/>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item ivl08">수행사명</div>
							<div class="item-ct">{{item.workCompanyNm}}</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">근무형태</div>
							<SelectComp type="text" tagName="div" class="item-ct" :value="item.workPatCd" cdId="PRO123"/>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">개발기간</div>
							<div class="item-ct">
								{{item.joinStartYyyymm | date('yyyy년 MM월')}} ~ {{item.joinEndYyyymm | date('yyyy년 MM월')}}
								<P class="term">[{{item.joinMonthCnt | months}}]</P>
							</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">수행업무</div>
							<div class="item-ct">{{item.exeWorkCont}}</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">수행직무</div>
							<div class="item-ct">{{item.exeDutyCont}}</div>
						</div>
					</div>
					<div class="layout">
						<div class="list">
							<div class="item">참여구분</div>
							<SelectComp type="text" tagName="div" class="item-ct" :value="item.joinDivCd" cdId="PRO124"/>
						</div>
					</div>
					<div class="layout flex">
						<div class="list">
							<div class="item">상세내용</div>
							<nbsp tagName="div" class="item-ct" :value="item.projectDtlDesc"/>
						</div>
					</div>
				</div>
			</template>
			<div class="view-list career none" v-else>등록 된 프로젝트 경력이 없습니다.</div>
		</div>
	</div>
</template>

<script>
export default {
	props: { data: Object,},
	methods: {
	},
	computed: {
		info(){
			if(this.data && Object.keys(this.data).length > 0){
				return this.data;
			} else {
				return {};
			}
		}
	}
}
</script>