<template>
	<fix-top>
		<template v-if="info == null">
			<div class="view-detail" style="display: none; right: -1000px;">
			</div>
		</template>
					
		<template v-else>
			<div class="view-detail">
				<div class="bt-close">
					<img src="/images/btn-close-layer.png" alt="닫기" @click="$emit('profileClose')">
				</div>
				<!-- 프로필, 평균평가등급, 고객사/프로엔 평가 -->
				<div class="view-list">
					<div class="profile">
						<div class="mem_num" v-if="info.joinPossYyyymmdd != undefined"><img src="/images/tec/myp/project_possible.png" alt="하이프로로고" /><span class="dev_state_pmg_tec">{{info.joinPossYyyymmdd | date('yyyy. MM. dd 부터')}}</span></div>
						<div class="mem_num imposs" v-else><img src="/images/tec/myp/project_impossible.png" alt="하이프로로고" /></div>
						<!-- <div class="mem_num"><img src="/images/logo_sm_01.png" alt="하이프로로고"/> 회원번호 <span class="num">{{tecMberSeq}}</span></div> -->
						<div class="image_box">
							<img v-if="info.gender == 'M'" src="/images/profile_df01.png" alt="프로필사진"/>
							<img v-if="info.gender == 'F'" src="/images/profile_df01_w.png" alt="프로필사진"/>
						</div>
						<div class="name">
							{{info.mberNm}}
							<span class="age">({{info.gender | gender }} / {{info.birthYyyymmdd | birthYear}})</span>
							<!-- <template v-if="!loginAuth('11,31')"> 경 력 <span class="career">{{info.totWorkMonthCnt | months}}</span></template> -->
							<span v-if="!loginAuth('11')" style="margin-left: 12px;"> 경 력 <span class="career">{{info.totWorkMonthCnt | months}}</span></span>
						</div>
						<div class="history" v-if="loginAuth('11')">경 력 <span class="career">{{info.totWorkMonthCnt | months}}</span></div>
						<!-- 공급기업_프로젝트 지원현황 : 소속사 노출-->
						<div v-if="pageId == '2'" class="history" style="margin-top: 5px; margin-bottom: 15px;">
							{{info.corpNm}} / <SelectComp type="text" cdId="PRO102" v-model="info.empDivCd" />
						</div>

						<!-- 프로젝트 인터뷰요청 -->
						<div class="prj_itv" v-if="loginAuth('22') && pageId != '2'" @click="reqInteview()">
							<img src="/images/prj_itv.png" alt="프로젝트인터뷰요청"/>
							<p>프로젝트 인터뷰 요청</p>
						</div>
						<!-- 프로젝트 수행중일때 -->
						<div class="prj_itv" v-if="info.projectNowTerm" @click="showProjectRecrRate()">
							<img src="/images/prj_pt.png" alt="프로젝트수행중"/>
						</div>
						<div class="human_int" v-if="loginAuth('22') && pageId != '2'" @click="setInterestYn()">
							<img :src="info.interestYn == 'Y' ? '/images/human_int_btn01.png' : '/images/human_int_btn00.png'" alt="관심인재"/>
							관심인재
						</div>
					</div>
					<div class="ranking">
						평균 평가등급
						<grade-img type="TOT_EVAL" :grade="info.totEvalGradeCd" :specialYn="info.specialGradeYn"/>
						<SelectComp type="text" tagName="p" :value="info.totEvalGradeCd" v-if="info.totEvalGradeCd" cdId="PRO126"/>
						<p v-else>미평가</p>
					</div>
					<div class="ct" :class="{sp:loginAuth('11,31')}">
						<div class="cstom">
							수 행 사 <nbsp/>평 가
							<!-- 고객사 평가 없는 경우 -->
							<div class="eval none" v-if="!info.clientEval"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div>
							<!-- 고객사 평가 있는 경우 -->
							<div class="eval" v-else v-for="(item, idx) in info.clientEval" :key="item.cd" :class="{first:idx == 0}">
								<SelectComp tagName="p" class="text" type="text" :value="item.cd" cdId="PRO110"/>
								<grade-img type="CLIENT_EVAL" :grade="item.grade"/>
							</div>
						</div>
					</div>
					<div class="ct" v-if="!loginAuth('11,31')">
						<div class="cstom">
							하이프로 <nbsp/>평 가
							<!-- 프로엔 평가 없는 경우 -->
							<div class="eval none" v-if="!info.pronEval"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div>
							<!-- 프로엔 평가 있는 경우 -->
							<div class="eval" v-else v-for="(item, idx) in info.pronEval" :key="item.cd" :class="{first:idx == 0}">
								<SelectComp tagName="p" class="text" type="text" :value="item.cd" cdId="PRO110"/>
								<grade-img type="PRON_EVAL" :grade="item.grade"/>
							</div>
						</div>
					</div>
				</div>

				<slide-comp ref="slide" :size="6" v-slot="slide" arrowLeft="/images/arrow_sm_left.png" arrowRight="/images/arrow_sm_right.png" :disabled="info.dtlViewYn != 'Y'" @slide-start="slideStart">
					<slide01 v-if="slide.index == 0" :data="info"/>
					<slide02 v-else-if="slide.index == 1" :data="info"/>
					<slide03 v-else-if="slide.index == 2" :data="info"/>
					<slide04 v-else-if="slide.index == 3" :data="info"/>
					<slide05 v-else-if="slide.index == 4" :data="info"/>
					<slide06 v-else-if="slide.index == 5" :data="info"/>
					<template v-else>
						404 Not Found
					</template>
				</slide-comp>
			</div>
		</template>
		
	</fix-top>
</template>

<script>
import gradeImg from "@/components/highpro/GradeImg.vue";
import slideComp from "@/components/SlideComp.vue";
import fixTop from "@/components/FixTop.vue";
import slide01 from "@/components/highpro/TecProfile/TecProfileSub01.vue";
import slide02 from "@/components/highpro/TecProfile/TecProfileSub02.vue";
import slide03 from "@/components/highpro/TecProfile/TecProfileSub03.vue";
import slide04 from "@/components/highpro/TecProfile/TecProfileSub04.vue";
import slide05 from "@/components/highpro/TecProfile/TecProfileSub05.vue";
import slide06 from "@/components/highpro/TecProfile/TecProfileSub06.vue";
export default {
	components: {
		gradeImg, slideComp, fixTop, slide01, slide02, slide03, slide04, slide05, slide06
	},
	props: {
		value: Object,
		tecMberSeq: {},
		scoProjectJoinMber:Boolean,
		//mberInfoYn: {},
	},
	data(){
		return {
			techMberSeq: this.tecMberSeq,
			pageId : this.$route.name || '',
			top: 0,
			//flag : false,
			insertViewHistoryFlag : 'Y',

			scoProjectJoinMberYn : this.scoProjectJoinMber
			
		}
	},
	watch: {
		tecMberSeq(){
			this.search(this.tecMberSeq);
		},

		// mberInfoYn(){
		// 	if(!this.flag){
		// 		this.search(this.tecMberSeq);
		// 	}
		// 	this.flag = false;
		// }
	},
	// updated() {
	// 	this.flag = false;
	// },
	mounted(){

		if(this.pageId == 'SRH201M01' || this.pageId == 'SRH302M01'){
			this.insertViewHistoryFlag = 'N'
		}

		if(this.pageId == 'PRJ404M01' || this.pageId == 'SRH301M01' || this.pageId == 'PRJ305M02' || this.pageId == 'PRJ306M02' || this.pageId == 'PRF301M01' || this.pageId == 'PRJ302M11'){
			this.pageId = '1'
		}else{
			this.pageId = '2'
		}

		this.techMberSeq = this.tecMberSeq;
		this.search(this.tecMberSeq);
	},
	methods: {
        search(tecMberSeq){
			
			// if(this.mberInfoYn == 'N'){
			// 	alert("참여소속이 변경된 기술인재입니다.");
			// 	this.flag = true;
			// 	return null;
			// }

			this.techMberSeq = tecMberSeq;
			if(this.techMberSeq){
				this.$.httpPost('/api/mem/prf/getTecInfo', { tecMberSeq : this.techMberSeq, pageId : this.pageId, insertViewHistoryFlag : this.insertViewHistoryFlag, scoProjectJoinMberYn : this.scoProjectJoinMber })
					.then(res => {
						this.$emit('input', res.data);
						if(this.$refs.slide){
							this.$refs.slide.slide(0);
						}
					}).catch(err => {
						// console.log('============================='); 
						// console.log(err);
						// console.log(err.response);
						// console.log('============================='); 

						if(err.response.data.error == 'PRF_ERR_002') {
							alert("탈퇴한 회원입니다.");
						}
					});
			} else {
				this.$emit('input', {});
			}

			//this.flag = true;
        },
		loginAuth(type){
			//console.log('TecInfo loginAuth', type.split(','), this.$store.state.userInfo.mberDivCd);
			return type.split(',').indexOf(this.$store.state.userInfo.mberDivCd) >= 0;
		},
		move(type, params){
			var name = null;
			switch(type.toUpperCase()){
				case 'NCS'		: name = 'PRF102M02'; break;
				case 'PROJECT'	: name = 'PRJ102M01'; break;
				case 'INTERVIEW': name = 'PRJ103M01'; break;
				case 'INTREST'	: name = 'PRJ104M01'; break;
				case 'EDITINFO'	: name = 'MYP102M01'; break;
				case 'POINT '	: break;//name = 'PRF103M01'; break;
				case 'RESUMEVIEW'	: name = 'PRF103M01'; break;
			}
			if(name != null){
				this.$emit('move', type.toUpperCase(), name, params);
				this.$router.push({name, params});
			} else {
				// console.log('TecInfo move', type);
			}
		},
		popup(type, params){
			var path = null;
			switch(type.toUpperCase()){
				case 'PROJECT' : path = '/tec/prj/PRJ102P01'; break;
			}
			if(path != null){
				this.$emit('popup', type.toUpperCase(), params);
				this.$.popup(path, params);
			} else {
				// console.log('TecInfo popup', type);
			}
		},
		showProjectRecrRate(){
			// console.log(this.info.projectNowTerm)
			if(this.info.projectNowTerm && this.info.projectNowTerm.length > 0){
				var term = this.info.projectNowTerm[0]
				this.$.popup('/dco/pmg/prf/PRF301P04', { recrStartYyyymmdd: term.joinStartYyyymmdd, recrEndYyyymmdd : term.joinEndYyyymmdd });
			}
		},
		reqInteview(){
			this.$.popup('/dco/pmg/prf/PRF301P02', { tecMberSeq: this.tecMberSeq })
				.then(res => {
					if(res){
						//this.search(this.tecMberSeq);
						this.$emit('updated', 'INTERVIEW', res);
					}
				});
		},
		setInterestYn(){
			this.$.popup('/dco/pmg/prf/PRF301P01', { tecMberSeq: this.tecMberSeq, regYn: this.info.interestYn == 'Y' ? 'N' : 'Y' })
				.then(res => {
					if(res){
						this.search(this.tecMberSeq);
						this.$emit('updated', 'INTEREST', res);
					}
				});
		},
		slideStart(start, end){
			if(this.info.dtlViewYn != 'Y' && start != end){
				//유료서비스 알림 팝업
				this.$.popup('/dco/pmg/prf/PRF301P07');
			}
		},
	},
	computed: {
		info(){
			if(this.value && Object.keys(this.value).length > 0){
				return this.value;
			} else {
				return null;
			}
		}
	}
}
</script>